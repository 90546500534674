// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getDeliveryZonesPreview, addDeliveryZones, editDeliveryZones } from '@/services/store.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      if (this.$route.params.id) {
        this.$store.state.app.loading = this.$loading(loading)
        getDeliveryZonesPreview(this.$route.params.id).then(res => {
          if (res.status === 200) {
            this.modifyData = res.data.delivery_zone
          }
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        delivery_zone: obj
      }
      let api = addDeliveryZones
      if (this.$route.params.id) {
        api = editDeliveryZones
      }
      api(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'deliveryZonesList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
