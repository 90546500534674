import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.store.zoneNamecn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.zoneNamecn') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.store.zoneNameen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.zoneNameen') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'postal_code_range_raw',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.store.postalCodeRange'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.postalCodeRange') }) },
          { pattern: /^([0-9]+,)*[0-9]+$/, message: i18n.t('form.store.validCommas') }
        ]
      }
    }]
  }]
}
